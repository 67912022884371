<template>
  <div class="tw-container">
    <!-- 實體帳號 合併 實體帳號 -->
    <div>
      <div class="d-flex justify-content-between">
        <p class="fw-bolder tw-text-size18 text-success mb-3"><i class="bi bi-pencil-square me-1"></i>實體帳號 合併 實體帳號</p>
        <div><button class="tw-btn tw-btn-danger" @click="showModal('deleteRedisData')">清除系統 Redis 資料</button></div>
      </div>
      <div class="alert-warning p-3 mb-2 fw-bolder">
        <p>備註:</p>
        <p>1. 這個就沒有回頭路了，整個資料合併上去</p>
        <p>2. 實體(FB) 併 實體(FB) > 無法合併</p>
        <p>3. 實體(Line) 併 實體(Line) > 無法合併</p>
        <p>4. 以關聯過的帳號不能在合併</p>
        <p>5. 若一個為 FB 一個為 Line 則合併完的結果，FB 跟 Line會同時存在</p>
      </div>
      <div class="row">
        <!-- 主帳號 -->
        <div class="col-5 p-0">
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="MasterAccount"
              placeholder="主帳號"
              v-model="mergeAccount.masterId"
              @keyup.enter="
                getParticipant(
                  'mergeAccount.masterLoading',
                  'mergeAccount.masterId',
                  'mergeAccount.master'
                )
              "
            />
            <label class="fw-bolder" for="MasterAccount">主帳號 ID</label>
          </div>
          <div
            class="fw-bolder alert"
            :class="{
              'alert-danger':
                !mergeAccount.masterLoading && !mergeAccount.master,
              'alert-warning': mergeAccount.masterLoading,
              'alert-primary': !mergeAccount.masterLoading && mergeAccount.master,
            }"
          >
            <p v-if="!mergeAccount.masterLoading && !mergeAccount.master">
              查無帳號 !
            </p>
            <p v-else-if="mergeAccount.masterLoading">查詢中...</p>
            <p v-else-if="!mergeAccount.masterLoading && mergeAccount.master">
              <ul class="list text-break">
                <li>ID: {{ mergeAccount.master.id }}</li>
                <li>名稱: {{ mergeAccount.master.user.name }}</li>
                <li>帳號: {{ mergeAccount.master.user.account }}</li>
                <li>Line: {{ mergeAccount.master.user.userLineLink.name }}</li>
                <li>Facebook: {{ mergeAccount.master.user.userFacebookLink.name }}</li>
                <li>是否在前台合併過: {{ mergeAccount.master.user.possibleToMerge ? '尚未合併' : '已合併' }}</li>
              </ul>
            </p>
          </div>
        </div>
        <!-- center -->
        <div
          class="
            d-flex
            flex-column
            justify-content-center
            align-items-center
            col-2
            p-0
            text-success
            fw-bolder
          "
        >
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-rewind-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M8.404 7.304a.802.802 0 0 0 0 1.392l6.363 3.692c.52.302 1.233-.043 1.233-.696V4.308c0-.653-.713-.998-1.233-.696L8.404 7.304Z"
              />
              <path
                d="M.404 7.304a.802.802 0 0 0 0 1.392l6.363 3.692c.52.302 1.233-.043 1.233-.696V4.308c0-.653-.713-.998-1.233-.696L.404 7.304Z"
              />
            </svg>
          </p>
          <p>合併至</p>
        </div>
        <!-- 被合併帳號 -->
        <div class="col-5 p-0">
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="SlaveAccount"
              placeholder="被合併帳號"
              v-model="mergeAccount.slaveId"
              @keyup.enter="getParticipant(
                  'mergeAccount.slaveLoading',
                  'mergeAccount.slaveId',
                  'mergeAccount.slave'
                )"
            />
            <label class="fw-bolder" for="SlaveAccount">被合併帳號 ID</label>
          </div>
          <div
            class="fw-bolder alert"
            :class="{
              'alert-danger':
                !mergeAccount.slaveLoading && !mergeAccount.slave,
              'alert-warning': mergeAccount.slaveLoading,
              'alert-success': !mergeAccount.slaveLoading && mergeAccount.slave,
            }"
          >
            <p v-if="!mergeAccount.slaveLoading && !mergeAccount.slave">
              查無帳號 !
            </p>
            <p v-else-if="mergeAccount.slaveLoading">查詢中...</p>
            <p v-else-if="!mergeAccount.slaveLoading && mergeAccount.slave">
              <ul class="list text-break">
                <li>ID: {{ mergeAccount.slave.id }}</li>
                <li>名稱: {{ mergeAccount.slave.user.name }}</li>
                <li>帳號: {{ mergeAccount.slave.user.account }}</li>
                <li>Line: {{ mergeAccount.slave.user.userLineLink.name }}</li>
                <li>Facebook: {{ mergeAccount.slave.user.userFacebookLink.name }}</li>
                <li>是否在前台合併過: {{ mergeAccount.slave.user.possibleToMerge ? '尚未合併' : '已合併' }}</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <!-- 確認合併按鈕 -->
      <button class="tw-btn tw-btn-gold w-100" @click="showModal('mergePhysicalUser')">確認合併</button>
    </div>
    <hr />
    <!-- 解除合併帳號 -->
    <div>
      <p class="fw-bolder tw-text-size18 text-danger mb-3"><i class="bi bi-pencil-square me-1"></i>解除合併帳號</p>
      <div class="alert-warning p-3 mb-2 fw-bolder">
        <p>備註:</p>
        <p>1. 若主帳號後續成為賣家的話，就不能解除</p>
      </div>
      <div class="row">
        <!-- 操作 -->
        <div class="col-md-6 col-12 p-0 mb-3">
          <!-- 輸入 ID -->
          <div class="form-floating">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="ParticipantId"
              placeholder="主帳號 ID 或 被合併帳號 ID"
              v-model="revokeAccount.participantId"
              @keyup.enter="
                getParticipant(
                  'revokeAccount.loading',
                  'revokeAccount.participantId',
                )
              "
            />
            <label class="fw-bolder" for="ParticipantId">
              "主帳號 ID" 或 "被合併帳號 ID"
            </label>
          </div>
        </div>
      </div>
      <!-- 顯示帳號 -->
      <div class="row fw-bolder mb-3">
        <div
          class="col-6 p-0 p-3 alert"
          :class="{
            'alert-warning': revokeAccount.loading,
            'alert-primary': !revokeAccount.loading && revokeAccount.master,
            'alert-danger': !revokeAccount.loading && !revokeAccount.master
          }"
        >
          <p v-if="revokeAccount.loading">查詢中...</p>
          <p v-else-if="!revokeAccount.loading && !revokeAccount.master">查無帳號 !</p>
          <ul class="list text-break" v-else-if="!revokeAccount.loading && revokeAccount.master">
            <li>主帳號：</li>
            <li>名稱: {{ revokeAccount.master.name }}</li>
            <li>帳號: {{ revokeAccount.master.account }}</li>
            <li>Line: {{ revokeAccount.master.userLineLink.name }}</li>
            <li>Facebook: {{ revokeAccount.master.userFacebookLink.name }}</li>
          </ul>
        </div>
        <div
          class="col-6 p-0 p-3 alert"
          :class="{
            'alert-warning': revokeAccount.loading,
            'alert-success': !revokeAccount.loading && revokeAccount.slave,
            'alert-danger': !revokeAccount.loading && !revokeAccount.slave
          }"
        >
          <p v-if="revokeAccount.loading">查詢中...</p>
          <p v-else-if="!revokeAccount.loading && !revokeAccount.slave">查無帳號 !</p>
          <ul class="list text-break" v-if="!revokeAccount.loading && revokeAccount.slave">
            <li>被合併帳號：</li>
            <li>名稱: {{ revokeAccount.slave.name }}</li>
            <li>帳號: {{ revokeAccount.slave.account }}</li>
            <li>Line: {{ revokeAccount.slave.userLineLink.name }}</li>
            <li>Facebook: {{ revokeAccount.slave.userFacebookLink.name }}</li>
          </ul>
        </div>
      </div>
      <!-- 解除合併按鈕 -->
      <button class="tw-btn tw-btn-gold w-100" @click="showModal('revokePhysicalUser')">確認解除合併按鈕</button>
    </div>
    <hr />
    <!-- 更改使用者名稱 -->
    <div>
      <p class="fw-bolder tw-text-size18 text-success mb-3"><i class="bi bi-pencil-square me-1"></i>更改使用者名稱</p>
      <div class="row">
        <div class="col-6 p-0">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="EmailAddress"
              placeholder="欲更改的使用者帳號(Email)"
              v-model="updateName.emailAddress"
              @keyup.enter="getPhysicalUser()"
            />
            <label class="fw-bolder" for="EmailAddress">欲更改的使用者帳號(Email)</label>
          </div>
        </div>
        <div class="col-6 p-0">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="NewName"
              placeholder="欲更改的新姓名(15字)"
              maxlength="15"
              v-model="updateName.newName"
            />
            <label class="fw-bolder" for="NewName">欲更改的新姓名(15字)</label>
          </div>
        </div>
      </div>
      <!-- 顯示帳號 -->
      <div 
      class="p-0 p-3 alert"
      :class="{
            'alert-warning': updateName.loading,
            'alert-primary': !updateName.loading && updateName.info,
            'alert-danger': !updateName.loading && !updateName.info
          }">
          <p v-if="updateName.loading">查詢中...</p>
          <p v-else-if="!updateName.loading && !updateName.info">查無帳號 !</p>
          <ul class="list text-break" v-else-if="!updateName.loading && updateName.info">
            <li>ID：{{ updateName.info.id }}</li>
            <li>名稱: {{ updateName.info.name }}</li>
            <li>帳號: {{ updateName.info.account }}</li>
            <li>Line: {{ updateName.info.userLineLink.name ? updateName.info.userLineLink.name : "無" }}</li>
            <li>Facebook: {{ updateName.info.userFacebookLink.name ? updateName.info.userFacebookLink.name : "無" }}</li>
          </ul>
      </div>
      <!-- 更改使用者名稱按鈕 -->
      <button class="tw-btn tw-btn-gold w-100" @click="showModal('updateName')">確認更改使用者名稱</button>
    </div>
  </div>
  <!-- 清除系統 deleteRedisData 的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="deleteRedisDataModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-danger">
          <h5 class="modal-title border-start ps-2 border-5 border-danger text-danger fw-bold">清除系統 Redis 資料</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- FB 社團留言進度條 -->
          <div>
            <input class="me-2" type="number" placeholder="社團ID" v-model="redisData.fbGroupLinkId">
            <button class="tw-btn tw-btn-secondary" @click="deleteRedisData('progressbarForGroup')">清除 特定<span class="text-danger fw-bolder"><span class="text-primary">社團</span>同步留言進度條</span></button>
          </div>
          <hr>
          <!-- FB 粉專留言進度條 -->
          <div>
            <input class="me-2" type="number" placeholder="粉專ID" v-model="redisData.fbPageLinkId">
            <button class="tw-btn tw-btn-secondary" @click="deleteRedisData('progressbarForPage')">清除 特定<span class="text-danger fw-bolder"><span class="text-success">粉專</span>同步留言進度條</span></button>
          </div>
          <hr>
          <!-- 訂閱 -->
          <div>
            <input class="me-2" type="text" placeholder="使用者ID" v-model="redisData.physicalUserId" readonly disabled>
            <button class="tw-btn tw-btn-secondary" @click="deleteRedisData('physicalUser')">清除所有使用者<span class="text-danger fw-bolder">訂閱資訊</span></button>
          </div>
          <hr>
          <!-- 所有 -->
          <div>
            <input class="me-2" type="text" value="*" readonly disabled>
            <button class="tw-btn tw-btn-danger" :disabled="!redisData.unlock" @click="deleteRedisData('all')">清除所有 Redis 資料</button>
            <div class="ms-2 d-inline-block">
              <input type="checkbox" name="" id="UnLock" v-model="redisData.unlock">
              <label for="UnLock">解鎖</label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 實體帳號 合併 實體帳號的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="mergePhysicalUserModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">實體帳號 合併 實體帳號</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="fw-bolder">我已確定<span class="text-success">合併</span> !</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="mergePhysicalUser">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 解除合併的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="revokePhysicalUserModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">解除合併帳號</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="fw-bolder">我已確定<span class="text-danger">解除合併</span> !</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="revokePhysicalUser">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 更改使用者名稱的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="updateNameModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">更改使用者名稱</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="fw-bolder">我已確定更改
            <span class="text-danger">{{updateName.emailAddress}}</span>
            使用者名稱為
            <span class="text-danger"> {{updateName.newName}}</span> !</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="updatePhysicalUserName()">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // modal
      deleteRedisDataModal: {},
      mergePhysicalUserModal: {},
      revokePhysicalUserModal: {},
      updateNameModal: {},
      // data
      b_serverToken: '',
      mergeAccount: {
        masterLoading: false,
        masterId: 0, // participantId
        master: null,
        slaveLoading: false,
        slaveId: 0, // participantId
        slave: null
      },
      revokeAccount: {
        loading: false,
        participantId: 0, // participantId
        master: null,
        slave: null, // 最後要取得 slave info
      },
      updateName: {
        loading: false,
        newName: null,
        emailAddress: null,
        info: null,
      },
      redisData: {
        fbGroupLinkId: 0,
        fbPageLinkId: 0,
        physicalUserId: "*",
        unlock: false,
      },
    }
  },
  created() {
    this.b_serverToken = this.$methods.getCookie('b_serverToken')
  },
  mounted() {
    this.createModals(['mergePhysicalUserModal', 'revokePhysicalUserModal', 'updateNameModal', 'deleteRedisDataModal'])
  },
  methods: {
    showModal(status) {
      if (status === 'deleteRedisData') {
        // 清除系統 Redis
        this.redisData = {
          fbGroupLinkId: 0,
          fbPageLinkId: 0,
          physicalUserId: "*",
          unlock: false,
        }
        this.deleteRedisDataModal.show()
      } else if (status === 'mergePhysicalUser') {
        // 實體帳號 合併 實體帳號
        if (!this.mergeAccount.master) return this.SweetAlert('other', '尚未有主帳號資料')
        if (!this.mergeAccount.slave) return this.SweetAlert('other', '尚未有被合併帳號資料')
        if (this.mergeAccount.master && this.mergeAccount.slave) {
          if (this.mergeAccount.master.id === this.mergeAccount.slave.id) return this.SweetAlert('other', '主帳號 與 被合併帳號 一樣')
          if (this.mergeAccount.master.userType !== "PHYSICALUSER") return this.SweetAlert('other', '主帳號不是實體顧客')
          if (this.mergeAccount.slave.userType !== "PHYSICALUSER") return this.SweetAlert('other', '被合併帳號不是實體顧客')
        }
        this.mergePhysicalUserModal.show()
      } else if (status === 'revokePhysicalUser') {
        // 解除合併按鈕
        if (!this.revokeAccount.master && !this.revokeAccount.slave) return this.SweetAlert('other', '此 ID 並不是被合併過的帳號')
        this.revokePhysicalUserModal.show()
      } else if (status === 'updateName') {
        // 更改使用者名稱
        if (!this.updateName.info || !this.updateName.emailAddress) return this.SweetAlert('other', '請在「欲更改使用者帳號(Email)」欄位按下 Enter<br>確認資訊是否為您要更改的帳號')
        if (!this.updateName.newName) return this.SweetAlert('other', '請輸入欲更改的新姓名')
        this.updateNameModal.show()
      } 
    },
    /**
     * 取得 participant 資訊
     * loading, record, record: 格式皆為 data 路徑
     */
    getParticipant(loading, particpantStatus, record) {
      // 處理局部 loading
      this[loading.split('.')[0]][loading.split('.')[1]] = true
      // 處理 participantId
      const participantId = this[particpantStatus.split('.')[0]][particpantStatus.split('.')[1]]
      // 清空帳號資訊 (解除合併沒有傳 record)
      if (record) this[record.split('.')[0]][record.split('.')[1]] = null
      // call api
      const getParticipantInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.b_serverToken
      }
      const data = [
        {
          type: 13,
          ids: [participantId],
          methods: '{getUser{getUserLineLink,getUserFacebookLink,getSlaves,isPossibleToMerge}}'
        }
      ]
      const vm = this
      $.ajax({
        type: 'POST',
        async: true,
        url: getParticipantInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const participantInfo = res.data[0].objects[0]
            // 如果是 "解除合併" 的話，就要多找 slave 或 master 的資訊
            if (loading === 'revokeAccount.loading') {
              vm.getRemainingInfo(participantInfo)
            } else {
              // 紀錄 participant 資訊
              if (participantInfo) vm[record.split('.')[0]][record.split('.')[1]] = participantInfo
              // 處理局部 loading
              vm[loading.split('.')[0]][loading.split('.')[1]] = false
            }
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          // 處理局部 loading
          vm[loading.split('.')[0]][loading.split('.')[1]] = false
        },
      })
    },
    // 清除系統 RedisData
    deleteRedisData(status) {
      const vm = this
      const deleteRedisApi = `${process.env.VUE_APP_API}/system/deleteRedisData`
      const data = new FormData()
      switch (status) {
        case "all":
          data.append("keys", "*")
          break;
        case "progressbarForGroup":
          if (!this.redisData.fbGroupLinkId) return this.SweetAlert("other", "請輸入社團 ID")
          data.append("keys", `ProgressBar:StoreFbGroupLink:${this.redisData.fbGroupLinkId}:sync-comments`)
          break;
        case "progressbarForPage":
          if (!this.redisData.fbPageLinkId) return this.SweetAlert("other", "請輸入粉專 ID")
          data.append("keys", `ProgressBar:StoreFbPageLink:${this.redisData.fbPageLinkId}:sync-comments`)
          break;
        case "physicalUser":
          if (!this.redisData.physicalUserId) return this.SweetAlert("other", "請輸入使用者 ID")
          data.append("keys", `PhysicalUser:${this.redisData.physicalUserId}`)
          break;
        default:
          throw new Error("AccountsChange.vue > deleteRedisData fn > 查無此 status")
      }
      this.$methods.switchLoading('show')
      $.ajax({
        type: 'DELETE',
        async: true,
        url: deleteRedisApi,
        data: data,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.deleteRedisDataModal.hide()
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 解除合併帳號 > 取得剩餘資訊
    getRemainingInfo(participantInfo) {
      let ids = []
      this.revokeAccount.master = null
      this.revokeAccount.slave = null
      // 輸入的 ID 不在資料庫內
      if (!participantInfo) return this.revokeAccount.loading = false
      // 輸入的 ID possibleToMerge === null
      if (participantInfo.user.possibleToMerge === null) return this.revokeAccount.loading = false
      // 正常被合併過帳號
      if (participantInfo.user.slaves.length > 0 && !participantInfo.user.masterId) {
        // 輸入的是 "主帳號" ID，[主帳號ID, 被合併帳號 ID]
        ids = [participantInfo.userId, participantInfo.user.slaves[0].id]
      } else if (participantInfo.user.slaves.length === 0 && participantInfo.user.masterId) {
        // 輸入的是 "被合併帳號" ID，[主帳號ID, 被合併帳號 ID]
        ids = [participantInfo.user.masterId, participantInfo.userId]
      } else if (participantInfo.user.slaves.length === 0 && !participantInfo.user.masterId) {
        // 兩者都不是
        return this.revokeAccount.loading = false
      }
      // call api
      const getPhysicalUserInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.b_serverToken
      }
      const data = [
        {
          type: 1,
          ids: ids,
          methods: '{getUserLineLink,getUserFacebookLink,getSlaves,isPossibleToMerge}'
        }
      ]
      const vm = this
      $.ajax({
        type: 'POST',
        async: true,
        url: getPhysicalUserInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const physicalUsersInfo = res.data[0].objects
            // 紀錄資料
            vm.sortoutRevokeAccountInfo(physicalUsersInfo)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.revokeAccount.loading = false
        },
      })
    },
    // 紀錄解除合併資料
    sortoutRevokeAccountInfo(physicalUsersInfo) {
      physicalUsersInfo.forEach(item => {
        // 主帳號
        if (item.slaves.length > 0 && !item.masterId) this.revokeAccount.master = item
        // 被合併帳號
        else if (item.slaves.length === 0 && item.masterId) this.revokeAccount.slave = item
      })
      this.revokeAccount.loading = false
    },
    // 實體帳號 合併 實體帳號
    mergePhysicalUser() {
      if (!this.mergeAccount.master.user.possibleToMerge) return this.SweetAlert('other', `主帳號已在前台合併過`)
      if (!this.mergeAccount.slave.user.possibleToMerge) return this.SweetAlert('other', `被合併帳號已在前台合併過`)
      this.$methods.switchLoading('show')
      const mergePhysicalUserApi = `${process.env.VUE_APP_API}/user/mergePhysicalUser`
      const header = {
        authorization: this.b_serverToken
      }
      const data = new FormData()
      data.append('masterAccount', this.mergeAccount.master.user.account)
      data.append('slaveAccount', this.mergeAccount.slave.user.account)
      const vm = this
      $.ajax({
        type: 'POST',
        async: true,
        url: mergePhysicalUserApi,
        data: data,
        headers: header,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.mergeAccount = {
              masterLoading: false,
              masterId: 0, // participantId
              master: null,
              slaveLoading: false,
              slaveId: 0, // participantId
              slave: null
            }
            vm.mergePhysicalUserModal.hide()
            vm.$methods.switchLoading('hide')
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 解除合併帳號
    revokePhysicalUser() {
      this.$methods.switchLoading('show')
      const revokePhysicalUserApi = `${process.env.VUE_APP_API}/user/revokeLinkedPhysicalUser`
      const header = {
        authorization: this.b_serverToken
      }
      const data = new FormData()
      data.append('slaveAccounts', this.revokeAccount.slave.account)
      const vm = this
      $.ajax({
        type: 'POST',
        async: true,
        url: revokePhysicalUserApi,
        data: data,
        headers: header,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.revokeAccount = {
              loading: false,
              participantId: 0,
              master: null,
              slave: null,
            }
            vm.revokePhysicalUserModal.hide()
            vm.$methods.switchLoading('hide')
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 取得 PhysicalUser 資訊
    getPhysicalUser() {
      if (!this.updateName.emailAddress) return this.SweetAlert('other', '請輸入欲更改姓名的使用者帳號(Email)')
      const getPhysicalUserInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.b_serverToken
      }
      const query =  {"columns":[{"columnName":"id","siteObjectType":1}],"group":{"groups":[],"operator":1},"siteObjectType":1,"sqlCommandType":1}
      query.group.groups.push({
        column: {
          columnName: "Account",
          siteObjectType: 1
        },
        operator: {
          operator: "=",
          type: 1,
          value: this.updateName.emailAddress
        }
      })
      const data = [
        {
          query: query,
          methods: '{getUserLineLink,getUserFacebookLink}'
        }
      ]
      const vm = this;
      this.updateName.loading = true;
      this.updateName.info = null;
      $.ajax({
        type: 'POST',
        async: true,
        url: getPhysicalUserInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const physicalUserInfo = res.data[0].objects
            // 如果查出來的結果，出現一個以上的physicalUser，就聯繫工程師
            if (physicalUserInfo.length === 1) vm.updateName.info = physicalUserInfo[0]
            else if (physicalUserInfo.length > 1) vm.SweetAlert('other', '您搜尋的帳號，出現兩筆以上使用者資訊<br>此為資料錯誤，請聯繫工程師')
          }
          vm.updateName.loading = false;
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.updateName.loading = false;
        },
      })
    },
    // 更改使用者名稱
    updatePhysicalUserName() {
      this.$methods.switchLoading('show')
      const updatePhysicalUserNameApi = `${process.env.VUE_APP_API}/user/updateName`
      const header = {
        authorization: this.b_serverToken
      }
      const data = new FormData()
      data.append('emailAddress', this.updateName.emailAddress)
      data.append('newName', this.updateName.newName)
      const vm = this;
      $.ajax({
        type: 'PUT',
        async: true,
        url: updatePhysicalUserNameApi,
        data: data,
        headers: header,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          vm.SweetAlert(res.code, res.message)
          if (res.code === '200') {
            vm.updateName = {
              loading: false,
              newName: null,
              emailAddress: null,
              info: null,
            }
            vm.updateNameModal.hide()
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    }
  },
}
</script>